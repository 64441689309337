<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Integrations</h3>
      </v-col>
      <v-col>
        <!-- <v-row align="center" justify="end" no-gutters>
          <v-btn small depressed color="accent" class="mr-2" @click="addContactForm.dialog = true">
            <v-icon left>mdi-plus</v-icon>Add Contact
          </v-btn>
        </v-row> -->
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <!-- <v-data-table
          :headers="tableHeaders"
          :items="customer.contacts"
          show-expand
          no-data-text="No contacts found"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  depressed
                  color="blue lighten-4 blue--text"
                  v-on="on"
                  class="mr-2"
                  @click="openEditContact(item.id)"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ item }">
            <div class="pa-4">
              <h4>Notes</h4>
              <div>{{ item.notes }}</div>
            </div>
          </template>
        </v-data-table> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Phone Number", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", align: "right" },
        { text: "", value: "data-table-expand" },
      ],

      relationshipUpdating: null,
    };
  },

  computed: {},

  methods: {},
};
</script>
